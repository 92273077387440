export const SectionHeading = {
  sectionHeading: {
    heading: {
      variant: "text.heading",
      primary: {
        color: "primary",
        fontSize: [5, 6],
        textAlign: ["center", "left"],
      },
      dark: {
        color: "text",
        fontSize: [5, 6],
        fontWeight: "bold",
      },
    },
    message: {},
  },
};
