import * as React from "react";
import { StoreProvider } from "./src/context/store-context";
import "./src/styles/styles.css";
import { AuthProvider } from "./src/context/authentication-context";
import netlifyIdentity from "netlify-identity-widget";

export function onInitialClientRender(a, options) {
   //console.log("AuthProvider USE EFFECT 0", netlifyIdentity, window.netlifyIdentity);

    //console.log("AuthProvider USE EFFECT", netlifyIdentity);
    // update user state on 'init event
   
    // initialize netlify identity
    //netlifyIdentity?.init();
  console.log("Initial render");
  //window.netlifyIdentity = netlifyIdentity;
  //window.netlifyIdentity.init();
}

export function onClientEntry() {
  console.log("Client entryr");

  netlifyIdentity.init();

  window.netlifyIdentity = netlifyIdentity;
}

export const wrapRootElement = ({ element }) => {
  //window.netlifyIdentity = netlifyIdentity;
  console.log("wrapRootElement", window.netlifyIdentity);
  return (
    <AuthProvider netlifyIdentity={window.netlifyIdentity}>
      <StoreProvider>{element}</StoreProvider>
    </AuthProvider>
  );
};
