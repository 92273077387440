import { roboto } from "@theme-ui/presets";
const colors = {
  colors: {
    ...roboto.colors,
    primary: "#80C342", //green
    primaryTransparent: "rgba(202, 236, 170, 0.85)",
    primaryLight: "#E8F4DD",
    gray: "#58585A",
    lightGray: "#E2E5E6",
    text: "#58585A",
    textLight: "#4d4d4d",
    inputBG: "rgba(0,0,0,0.05)",
    inputBorder: "rgba(0,0,0,0.05)",
    modalBackdrop: `rgba(30, 30, 30, 0.5)`,
  },
  backgrounds: {
    section: {
      backgroundImage: (t) => `
      linear-gradient(180deg, rgba(232, 244, 221, 0.1) 0%, #80C342 42.55%, rgba(232, 244, 221, 0.1) 86.9%)`,
    },
  },
};

export default colors;
