export const Hero = {
  hero: {
    background: {
      position: "absolute",
      top: 0,
      //height: "560px",
      width: "100%",
      zIndex: -1,
    },
    contentWrapper: {
      //mt:1,
      //top: [0, 4],
      //left: [0, 0],
      //left: 0,
      //right:0,
      variant: "layout.box",
      //position: "absolute",
      width: ["100%"],
      justifyContent: "center",
      alignItems: ["center", "flex-start"],
      display: "flex",
      color: "gray",
      flexDirection: ["column"],
      pl: [2, 0],
    },
    h1: {
      width: "100%",
      display: "flex",
      flexDirection: ["column"],
      fontWeight: ["bold", "700"],
      fontSize: [6, 8],
      //textTransform: "capitalize",
      textAlign: ["center", "left"],
      alignItems: ["center", "flex-start"],
      span: { lineHeight: ["30px", "107px"] },
      pb: [1, 4],
      shop: {
        width: "100%",
        display: "flex",
        flexDirection: ["column"],
        fontWeight: ["bold", "700"],
        fontSize: [4, 6],
        textAlign: ["center", "left"],
        //textTransform: "capitalize",
        alignItems: ["center", "flex-start"],
        span: { lineHeight: ["30px", "107px"] },
        pb: [1, 4],
      },
    },
    message: {
      width: ["100%", "40%"],
      //fontWeight: ["bold"],
      fontSize: [2, 4],
      textAlign: ["center", "left"],
      color: "text",
      pb: [4],
    },
  },
};
