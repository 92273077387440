const links = {
  links: {
    nav: {
      //color: "white",
      fontWeight: "700",
      fontSize: [3, 2],
      "&.active": {
        fontWeight: "Bold",
        color: "primary",
      },
      highlight: {
        //color: "white",
        fontWeight: "700",
        fontSize: [3, 2],
        bg: "primary",
        px: 3,
        py: 1,
        borderRadius: "16px",
      },
    },
  },
};

export default links;
