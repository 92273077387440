const cards = {
  cards: {
    primary: {
      padding: 4,
      borderRadius: "card",
      boxShadow: [

        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      ],
      bg: "white",
    },
    productCategory: {
      padding: 1,

      borderRadius: "small",

      boxShadow: [

        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      ],
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
    promo: {
      padding: 4,
      borderRadius: "card",
      width: "fit-content",
      boxShadow: [

        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      ],
      bg: "primaryLight",
    },
    toc: {
      padding: 3,
      borderRadius: "8px",
      border: "1px solid",
      borderColor: "muted",
      bg: "primaryLight",
      height: "fit-content",
    },
    callout: {
      padding: 3,
      borderRadius: "10px",
      bg: "primaryLight",
      textAlign: "center",
      boxShadow:
        "0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14)",
      p: {
        mb: "0 !important",
      },
    },
    icon: {
      display: "flex",
      flexDirection: "column",
      alignItems: ["center"],
      justifyContent: ["flex-start"],

      h3: {
        textTransform: "uppercase",
        textAlign: ["center"],
        fontWeight: "bold",
      },
      p: {
        textAlign: ["center"],
      },

      horizontal: {
        display: "flex",
        flexDirection: "row",
        alignItems: ["flex-start"],
        justifyContent: ["flex-start"],

        h3: {
          pl: 3,
          textTransform: "capitalize",
          textAlign: ["left"],
          fontWeight: "bold",
        },
        p: {
          textAlign: ["center"],
        },
      },
    },
  },
};

export default cards;
