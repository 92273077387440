import { roboto } from "@theme-ui/presets";
import buttons from "./buttons";
import cards from "./cards";
import colors from "./colors";
import forms from "./forms";
import { Hero } from "./hero";
import layout from "./layouts";
import links from "./links";
import { SectionHeading } from "./section-heading";
import typography from "./typography";

export default {
  ...roboto,
  ...colors,
  ...typography,
  ...Hero,
  ...buttons,
  ...layout,
  ...cards,
  ...links,
  ...SectionHeading,
  radii: { card: "20px", button: "16px", small: "12px" },

  ...forms,
  modals: {
    primary: {
      backDrop: {
        zIndex: 9999,
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        bg: "modalBackdrop",
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
        //pointerEvents: "none",
      },
      container: {
        position: "relative",
        bg: "primaryLight",
        p: 4,
        borderRadius: 4,
      },
      close: {
        position: "absolute",
        top: 0,
        right: 0,
        color: "primary",
      },
    },
    dark: {
      backDrop: {
        variant: "modals.primary.backDrop",
      },
      container: {
        variant: "modals.primary.container",
        bg: "primary",
      },
      close: {
        variant: "modals.primary.close",
        color: "primaryLight",
      },
    },
  },

  tables: {
    li: { p: { display: "block!important" } },
    default: {
      padding: 1,
      textAlign: "center",
      textOverflow: "elipses",
      borderRightWidth: "2px",
      borderRightColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      variant: "tables.default",
      p: 2,
      position: "sticky",
      top: "0",
      bg: "primary",
      fontWeight: "bold",
      fontSize: 3,
      color: "white",
    },
    headerRow: {
      variant: "tables.default",
      fontWeight: "bold",
      textAlign: "left",
    },

    feature: {
      variant: "tables.default",
      headerRow: {
        variant: "tables.headerRow",
        justifyContent: ["flex-start"],
      },
      header: {
        variant: "tables.header",
        headerRow: { variant: "tables.header" },
      },
      banded: {
        variant: "tables.default",
        bg: "primaryLight",

        headerRow: {
          variant: "tables.headerRow",
          bg: "primaryLight",
          justifyContent: ["flex-start"],
        },
      },
    },
  },

  image: {
    product: {
      borderStyle: "solid",
      borderWidth: "0px",
      borderColor: "lightGray",
      thumb: {
        borderStyle: "solid",
        borderWidth: "0px",
        borderColor: "lightGray",
        selected: {
          borderStyle: "solid",
          borderWidth: "0px",
          borderColor: "primary",
        },
      },
    },
  },
  nav: {
    main: {
      textTransform: "uppercase",
      cursor: "pointer",
      alignItems: "center",
      color: "white",
      fontWeight: "700",
      height: "100%",
      width: "100%",
      active: {
        textTransform: "uppercase",
        cursor: "pointer",
        alignItems: "center",
        color: "white",
        fontWeight: "700",
        height: "100%",
        width: "100%",
        borderBottomColor: "white",
        borderBottomWidth: "3px",
        borderBottomStyle: "solid",
      },
      highlight: {
        color: ["primary", "white"],
        fontWeight: "700",
        width: ["30%", "100%"],
        fontSize: [3, 2],
        bg: ["white", "primary"],
        px: 3,
        py: 1,
        mb: [3, 0],
        borderRadius: "16px",
        alignItems: "center",
        active: {
          color: ["primary", "white"],
          fontWeight: "700",
          width: ["30%", "100%"],
          fontSize: [3, 2],
          bg: ["white", "primary"],
          px: 3,
          py: 1,
          mb: [3, 0],
          borderRadius: "16px",
          alignItems: "center",
        },
      },
      subMenu: {
        bg: ["primaryLight", "white"],

        //padding: 4,
        borderRadius: [0, "4px"],
        boxShadow: ["none", "0 0 8px rgba(0, 0, 0, 0.125)"],
        p: [0, 4],
        //display: ["block", "none"],
        position: ["relative", "absolute"],
        width: ["100%", "max-content"],
        top: [0, 40],
        li: {
          color: "text",
        },
      },
      chevron: {
        transform: `rotate(90deg)`,
        color: "white",
        open: {
          transform: `rotate(270deg)`,
          bg: {
            bg: "white",
            borderRadius: "50%",
            minWidth: "30px",
            minHeight: "30px",

            color: "primary",
          },
        },
      },
    },
    shop: {
      textTransform: "capitalize",
      cursor: "pointer",
      alignItems: "center",
      color: "white",

      active: {
        textTransform: "capitalize",
        cursor: "pointer",
        alignItems: "center",
        color: "white",
        borderBottomColor: "white",
        borderBottomWidth: "3px",
        borderBottomStyle: "solid",
      },
      subMenu: {
        bg: ["transparent", "white"],

        //padding: 4,
        borderRadius: [0, "4px"],
        boxShadow: ["none", "0 0 8px rgba(0, 0, 0, 0.125)"],
        p: [0, 4],
        //display: ["block", "none"],
        position: ["relative", "absolute"],
        width: ["100%", "max-content"],
        top: [0, 100],
        li: {
          color: "text",
        },
      },
      chevron: {
        transform: `rotate(90deg)`,
        color: "white",
        open: {
          transform: `rotate(270deg)`,
          bg: {
            bg: "white",
            borderRadius: "50%",
            minWidth: "30px",
            minHeight: "30px",

            color: "primary",
          },
        },
      },
    },
  },
};
