export const sharedFormElementStyles = {
  //focus:outline-none focus:bg-white focus:border-gray-500"
  appearence: "none",
  display: "block",
  width: "100%",
  backgroundColor: "white",
  color: "dark",
  borderColor: "primary",
  borderRadius: "4px",
  borderWidth: "1px",
  py: 2,
  px: 2,
  fontFamily: "body",

  "&:focus": {
    outline: "none",
    backgroundColor: "white",
    borderColor: "primary",
  },
};

const forms = {
  forms: {
    label: {
      fontFamily: "body",
      fontSize: ".75rem",
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: 0.5,
      color: "text",
      mb: 1,
      width: "auto",
      //pt: [4],
      //block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2
    },
    input: {
      ...sharedFormElementStyles,
      //borderColor: "supportingALight",
      numeric: {
        display: "block",
        width: "100%",
        backgroundColor: "transparent",
        color: "dark",
        borderRightColor: "inputBorder",
        borderWidth: "0px",
        borderRadius: "0px",
        borderRightWidth: "1px",
      },
    },
    select: {
      display: "block",
      width: "100%",
      backgroundColor: "white",
      color: "dark",
      borderColor: "primary",
      borderRadius: "4px",
      "&:focus": {
        outline: "none",
        backgroundColor: "white",
        borderColor: "primary",
      },
    },
    checkbox: {
      width: "16px",
      height: "16px",
      borderRadius: "4px",
      display: "inline-flex",
      mr: 2,
      justifyContent: "center",
      alignItems: "center",
      appearance: "none",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "textLight",
      cursor: "pointer",
      ":checked": {
        bg: "primary",
        borderColor: "primary",
      },
    },
    textarea: {
      ...sharedFormElementStyles,
      //borderColor: "supportingALight",
    },
    slider: {
      bg: "muted",
    },
    validationError: {
      fontStyle: "italic",
      fontSize: ".75rem",
      color: "primary",
    },
    radio: {
      imageRadio: {
        position: "absolute",
        left: "-9999px",
        "&:checked + div": {
          variant: "cards.radioIcon.selected",
        },
      },
    },
  },
};

export default forms;
