declare global {
  interface Window {
    netlifyIdentity: any;
  }
}

import React, { useState, createContext, useEffect } from "react";
//import netlifyIdentity from "netlify-identity-widget";
const defaultValues = { user: null, login: () => {}, logout: () => {} };

export const AuthContext = createContext(defaultValues);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  // const [netlifyIdentity, setNetlifyIdentity] = useState(null);

  useEffect(() => {
    //console.log("AuthProvider USE EFFECT 0", netlifyIdentity, window.netlifyIdentity);

    if (window.netlifyIdentity) {
      setUser(window.netlifyIdentity.currentUser());
    }

    //console.log("AuthProvider USE EFFECT", netlifyIdentity);
    // update user state on 'init event
    window.netlifyIdentity.on("init", (user) => {
      console.log("init EVENT ", user);
      setUser(user);
    });
    // update user state after login
    window.netlifyIdentity?.on("login", (user) => {
      setUser(user);
      // close the modal
      window.netlifyIdentity?.close();
    });

    window.netlifyIdentity?.on("logout", () => {
      setUser(null);
    });
    // initialize netlify identity
    // window.netlifyIdentity?.init();
  }, []);

  const login = () => {
    window.netlifyIdentity?.open("login");
  };

  const logout = () => {
    window.netlifyIdentity?.logout();
  };

  const contextValues = { user };
  return (
    <AuthContext.Provider
      value={{
        ...defaultValues,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
